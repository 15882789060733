import React from "react";
import { Box, Stack, useTheme, styled } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { customColors } from "models/customColors";

const NoData = styled(Stack)`
  padding: 1.7rem 3rem;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  font-size: 1.125rem;
`;

const Item = styled(NoData)`
  flex-direction: row;
  justify-content: space-between;
  text-align: left;

  &.selectable {
    cursor: pointer;
    user-select: none;
  }

  &:hover {
    background-color: ${customColors.hover};
  }

  @media screen and (max-device-height: 400px) {
    padding: 1.2rem 3rem;
  }
`;

const Description = styled(Box)`
  margin-top: 0.3rem;
  font-size: 0.9rem;
`;

const borderBottomSx = { borderBottom: `thin solid ${customColors.border}` };

export const AppleChoice = ({ items, noItemsMessage = undefined }) => {
  const theme = useTheme();

  return (
    <Box sx={{ maxHeight: "350px", overflowY: "auto" }}>
      <Stack justifyContent={"center"} alignItems={"stretch"} gap={0}>
        <Box sx={borderBottomSx}></Box>
        {!items?.length && !!noItemsMessage && (
          <NoData
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              ...borderBottomSx,
              color: customColors.text.inactive,
              textAlign: "center",
            }}
          >
            {noItemsMessage}
          </NoData>
        )}
        {items.map(
          (
            {
              label,
              action,
              description,
              bindActionToIcon = false,
              actionIcon = (
                <ArrowForwardIosIcon
                  sx={{ color: customColors.text.inactive }}
                />
              ),
            },
            index
          ) => (
            <Item
              key={`${label}-${index}-{description}`}
              onClick={bindActionToIcon ? undefined : action}
              sx={{
                color: theme.palette.text.primary,
                ...borderBottomSx,
              }}
              className={bindActionToIcon ? "" : "selectable"}
            >
              <Stack>
                {label}
                <Description sx={{ color: theme.palette.text.disabled }}>
                  {description}
                </Description>
              </Stack>
              <Box onClick={bindActionToIcon ? action : undefined}>
                {actionIcon}
              </Box>
            </Item>
          )
        )}
      </Stack>
    </Box>
  );
};
